<template>
  <div class="ml-3 mr-3" v-if="section">
    <div class="mt-5 mb-2">
      <template v-if="section.egress_profile_matter != null">
        <h4>
          Información de la
          {{
            $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
          }}
        </h4>
        <NewMatterPresentation
          class="mt-3"
          v-if="egress_profile_matter && matter"
          :Matter="egress_profile_matter"
          :oldMatter="matter"
        ></NewMatterPresentation>
      </template>
      <template v-else-if="section.matter != null">
        <h4>
          Información de la
          {{ $getVisibleNames("teaching.matter", false, "Asignatura Base") }}
        </h4>
        <NewMatterPresentation
          class="mt-3"
          v-if="matter"
          :oldMatter="matter"
        ></NewMatterPresentation>
      </template>
    </div>
    <div class="mb-4">
      <h2>
        Registro de Notas de la
        {{ $getVisibleNames("teaching.section", false, "Sección") }}:
        <strong>
          {{ section.name }}
        </strong>
      </h2>
    </div>
    <div class="flex-container">
      <div class="search noprint m-1 p-2 w-100">
        <b-input-group size="sm">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="search-form"
            type="search"
            v-model="input_search"
            placeholder="Buscar Estudiantes..."
          >
          </b-form-input>
          <b-input-group-append>
            <b-button :disabled="!input_search" @click="input_search = ''"
              >Limpiar</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </div>
      <div v-if="evaluationTypesList.length > 0" class="w-50">
        <v-select
          class="mt-2 v-select-class"
          id="select-selected_types"
          :placeholder="`Seleccione uno o varios para filtrar.`"
          :options="evaluationTypesList"
          v-model="selected_types"
          :reduce="(evaluationTypesList) => evaluationTypesList.id"
          :selectable="(option) => !selected_types.includes(option.id)"
          label="value"
          track-by="id"
          size="sm"
          multiple
          :dropdown-should-open="dropdownShouldOpen"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
        </v-select>
      </div>
      <div class="column-selector noprint" v-if="displayList.length > 0">
        <b-button
          :id="`col-display-1`"
          class="col-display-1 px-5"
          variant="none"
          size="sm"
          >Visualizar columnas</b-button
        >
        <b-popover
          :id="`columns-popover-1`"
          :target="`col-display-1`"
          placement="bottom"
          class="col-popover-1"
          triggers="click blur"
        >
          <template v-if="current_section != null">
            <template v-for="evaluation in filterEvaluation">
              <template
                v-for="(instrument, index) in displayList.filter(
                  (x) => x.evaluation_id == evaluation.id
                )"
              >
                <div
                  :key="
                    'question_tests' +
                    instrument.index +
                    'evaluation' +
                    evaluation.id
                  "
                >
                  <div v-if="index == 0">
                    <strong>
                      {{ evaluation.title }}
                    </strong>
                  </div>
                  <div class="column-check noprint">
                    <b-form-checkbox
                      :id="`column-check-${instrument.index}`"
                      name="checkbox-1"
                      :value="instrument.index"
                      v-model="check_instrument"
                    >
                      {{ instrument.title }}
                    </b-form-checkbox>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </b-popover>
      </div>
    </div>

    <b-table-simple
      bordered
      responsive
      hover
      v-if="current_section"
      class="table-current-section mb-5"
    >
      <b-thead>
        <b-tr>
          <b-th rowspan="2" class="header-section">
            <div>Estudiante</div>
          </b-th>
          <b-th
            v-for="evaluation in filterEvaluation"
            :key="evaluation.id"
            class="header-section"
            @click="redirectEvaluatorView(evaluation.id)"
            :colspan="showColumn(evaluation.tests).length + 1"
          >
            <div>
              <div
                class="div-href"
                v-b-tooltip.v-secondary.noninteractive.hover="
                  'Ir a la Evaluación'
                "
              >
                <span class="underline-style">
                  {{ evaluation.title }} - {{ evaluation.weighing }}%</span
                >
              </div>
            </div>
          </b-th>
          <b-th rowspan="2" class="header-section"><div>Nota Final</div></b-th>
        </b-tr>
        <b-tr>
          <template v-for="evaluation in filterEvaluation">
            <b-th
              class="header-section"
              v-for="instrument in showColumn(evaluation.tests)"
              :key="evaluation.id + ':' + instrument.id"
              ><span :key="evaluation.id + ':' + instrument.id">
                <span>
                  {{ instrument.title }} (<span
                    v-b-tooltip.v-secondary.noninteractive.hover="
                      'Puntaje máximo: ' + instrument.max_score
                    "
                    >{{ instrument.max_score | Round }}</span
                  >) -
                  <span
                    v-b-tooltip.v-secondary.noninteractive.hover="
                      'Ponderación del ' +
                      $getVisibleNames(
                        'evaluations2.test',
                        false,
                        'Instrumento'
                      ) +
                      ': ' +
                      instrument.weighing
                    "
                    >{{ instrument.weighing | Round }}%</span
                  >
                </span>
                <b-icon
                  v-if="allows_crud && instrument && !instrument.all_published"
                  icon="cloud-arrow-up"
                  v-b-tooltip.right.hover.v-secondary.v-secondary="
                    `Presione aquí para publicar el puntaje del ${$getVisibleNames(
                      'evaluations2.test',
                      false,
                      'Instrumento'
                    )} seleccionado.`
                  "
                  @click="publishGradeToAllEvaluatees(instrument.id)"
                  class="style-icon-btn ml-1"
                ></b-icon>
              </span>
            </b-th>
            <b-th class="header-section" :key="evaluation.id">
              Nota {{ evaluation.name }}
            </b-th>
          </template>
        </b-tr>
      </b-thead>
      <b-tbody>
        <tr v-for="student in studentList" :key="student.student_id">
          <td class="text-left vertical-align-td">
            {{ student.first_name }} {{ student.last_name }}
          </td>
          <template v-for="evaluation in filterEvaluation">
            <template v-for="instrument in showColumn(evaluation.tests)">
              <NewFullGradingInputComponent
                class="td-class vertical-align-td"
                :key="evaluation.id + ':' + instrument.id"
                v-if="instrument.evaluatees.includes(student.id)"
                :context="'test'"
                :grade_ranges="grade_ranges"
                :student="student"
                :evaluatee_evaluations="student.evaluatee_evaluations"
                :evaluation="evaluation"
                :test="instrument"
                :allows_crud="allows_crud"
                :selected_range_group="selected_range_group"
                @change_score="resetTable"
              ></NewFullGradingInputComponent>
              <td
                :key="evaluation.id + ':' + instrument.id"
                v-b-tooltip.v-secondary.noninteractive.hover="
                  `${student.first_name} ${
                    student.last_name
                  } no se encuentra entre los usuarios que pueden contestar el ${$getVisibleNames(
                    'evaluations2.test',
                    false,
                    'Instrumento'
                  )}.`
                "
                v-else
              >
                <b-icon icon="person-x" scale="1.5"></b-icon>
              </td>
            </template>
            <NewFullGradingInputComponent
              class="border-right vertical-align-td min-width-input"
              :key="evaluation.id"
              v-if="
                evaluation.evaluatees.includes(student.id) ||
                getStudentIdsInTest(evaluation.tests).includes(student.id)
              "
              :context="'evaluation'"
              :grade_ranges="grade_ranges"
              :student="student"
              :evaluatee_evaluations="student.evaluatee_evaluations"
              :evaluation="evaluation"
              :allows_crud="allows_crud"
              :selected_range_group="selected_range_group"
              @change_score="resetTable"
            ></NewFullGradingInputComponent>
            <td v-else :key="evaluation.id">
              <div
                v-b-tooltip.v-secondary.noninteractive.hover="
                  `${student.first_name} ${
                    student.last_name
                  } no se encuentra entre los usuarios que pueden contestar el ${$getVisibleNames(
                    'evaluations2.test',
                    false,
                    'Instrumento'
                  )}.`
                "
              >
                <b-icon icon="person-x" scale="1.5"></b-icon>
              </div>
            </td>
          </template>
          <!-- Nota Final -->
          <td
            v-if="student.section_grade == null"
            class="vertical-align-td"
            :style="{
              'background-color':
                !allows_crud && student.section_grade != null
                  ? gradeRangeBackground(institution.min_grade) + ' !important'
                  : '',
            }"
          >
            <template>
              {{ institution.min_grade }}
            </template>
          </td>
          <td
            v-else
            class="vertical-align-td"
            :style="{
              'background-color': !allows_crud
                ? gradeRangeBackground(getFinalGrade(student.section_grade)) +
                  ' !important'
                : '',
            }"
          >
            <template>
              {{ getFinalGrade(student.section_grade) }}
            </template>
          </td>
        </tr>
        <tr v-if="studentList.length == 0">
          <td :colspan="rowColspan()">
            No hay datos que coincidan con su búsqueda.
          </td>
        </tr>
      </b-tbody>
    </b-table-simple>
    <GradeTableRanges
      :grade_ranges="grade_ranges"
      :grade_ranges_groups="grade_ranges_groups"
      :grade_range_group_id="career_range_group"
      @selected_range_group="(value) => (selected_range_group = value)"
    ></GradeTableRanges>
    <!-- :total_grades="totalGrades" -->
    <div class="info">
      <h5 class="info-title">
        <b-icon icon="info-circle"></b-icon>Simbología / Significados
      </h5>
      <div class="info-container">
        <div class="d-flex" v-if="allows_crud">
          <b-icon icon="save2" class="mr-1"></b-icon>
          <p>
            Guarda la nota ingresada del
            {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }} o
            Evaluación.
          </p>
        </div>
        <div class="d-flex mt-2" v-if="allows_crud">
          <b-icon icon="arrow-repeat" class="mr-1"></b-icon>
          <p>Actualiza el puntaje/nota al valor Automático.</p>
        </div>
        <!-- <div v-if="allows_crud">
          <div class="d-flex mt-1 ml-4">
            <b-icon icon="arrow-repeat" class="mt-1 mr-1"></b-icon>
            <strong> Evaluación:</strong> Aplica la suma de puntajes de los
            instrumentos asociados.
          </div>
          <div class="d-flex mt-1 ml-4">
            <b-icon icon="arrow-repeat" class="mt-1 mr-1"></b-icon>
            <strong> Instrumento:</strong> Aplica la suma de puntajes de las
            preguntas asociadas
          </div>
        </div> -->
        <div>
          <div class="d-flex mt-2">
            <b-icon icon="cloud-arrow-up" class="mr-1" scale="1"></b-icon>
            <p>
              Publica el puntaje del
              {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
              seleccionado a los estudiantes.
            </p>
          </div>
          <!-- <div class="d-flex mt-1">
            <b-icon icon="journal-text" class="mr-1"></b-icon>
            <p>Permite al docente publicar todas las notas simultáneamente.</p>
          </div> -->
          <!-- <div class="d-flex mt-1">
            <b-icon icon="eye"></b-icon>
            <b-icon icon="eye-slash"></b-icon>
            <span class="ml-2">
              Indica si la nota está publicada para el estudiante.
            </span>
          </div> -->

          <div class="d-flex mt-2">
            <b-icon
              icon="lightbulb"
              class="mr-1 margin-icon-symbology"
            ></b-icon>
            <p>
              Indica que se está utilizando el puntaje o nota automática para
              {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
              o Evaluación.
            </p>
          </div>

          <div class="d-flex mt-2">
            <b-icon icon="journal-check" class="margin-icon-symbology"></b-icon>
            <b-icon
              icon="journal-x"
              class="mr-2 margin-icon-symbology"
            ></b-icon>
            <p>
              Indica que el estudiante no puede contestar el
              {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}.
            </p>
          </div>
          <div class="d-flex mt-2">
            <b-icon
              icon="person-x"
              scale="1.2"
              class="mr-1 margin-icon-symbology"
            ></b-icon>
            <p class="ml-1">
              Indica que el estudiante no puede contestar el
              {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "NewFullGradingView",
  components: {
    NewMatterPresentation: () =>
      import(
        "@/components/teaching/MatterProgram/Components/NewMatterPresentation.vue"
      ),
    GradeTableRanges: () => import("./GradeTableRanges"),
    NewFullGradingInputComponent: () =>
      import("./NewFullGradingInputComponent"),
    vSelect,
  },
  props: {},
  data() {
    return {
      section_id: Number(this.$route.params.section_id),
      current_section: null,
      input_search: "",
      displayList: [],
      check_instrument: [],
      selected_types: [],
      evaluationTypesList: [],
      grade_ranges: [],
      grade_ranges_groups: [],
      selected_range_group: null,
      career_range_group: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      sections: names.SECTIONS,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
      evaluationTypes: names.NEW_EVALUATION_TYPES,
      matters: names.MATTERS,
    }),
    // totalGrades() {
    //   if (!this.institution || !this.current_section) return [];
    //   let count_grades = [];
    //   this.current_section.students.forEach((x) => {
    //     x.evaluatee_evaluations.forEach((y) => {
    //       count_grades.push(
    //         y.final_grade != null
    //           ? y.final_grade
    //           : y.manual_grade != null
    //           ? y.manual_grade
    //           : y.automated_grade != null
    //           ? y.automated_grade
    //           : this.institution.min_grade
    //       );
    //       y.evaluatee_tests.forEach((z) => {
    //         count_grades.push(
    //           z.final_grade != null
    //             ? z.final_grade
    //             : z.manual_grade != null
    //             ? z.manual_grade
    //             : z.automated_grade != null
    //             ? z.automated_grade
    //             : this.institution.min_grade
    //         );
    //       });
    //     });
    //     if (x.section_grade) {
    //       count_grades.push(
    //         x.section_grade.final_grade != null
    //           ? x.section_grade.final_grade
    //           : x.section_grade.manual_grade != null
    //           ? x.section_grade.manual_grade
    //           : x.section_grade.automated_grade != null
    //           ? x.section_grade.automated_grade
    //           : this.institution.min_grade
    //       );
    //     }
    //   });
    //   return count_grades;
    // },
    section() {
      if (!this.section_id) return null;
      return this.sections.find((x) => x.id == this.section_id);
    },
    filterEvaluation() {
      if (!this.current_section) return [];
      else {
        if (this.selected_types.length > 0)
          return this.current_section.evaluations.filter((x) =>
            this.selected_types.includes(x.type)
          );
        else return this.current_section.evaluations;
      }
    },
    egress_profile_matter() {
      if (this.section && this.section.egress_profile_matter != null)
        return this.egress_profile_matters.find(
          (x) => x.id == this.section.egress_profile_matter
        );
      else return null;
    },
    matter() {
      if (this.section && this.section.matter != null)
        return this.matters.find((x) => x.id == this.section.matter);
      else if (
        this.egress_profile_matter &&
        this.egress_profile_matter.matter != null
      )
        return this.matters.find(
          (x) => x.id == this.egress_profile_matter.matter
        );
      else return null;
    },
    allows_crud() {
      const has_permission = this.$hasPermissions(
        "evaluations2.change_evaluateeevaluation"
      );
      // TODO: cambiar cuando se agrege un permiso especial para poder cambiar las notas de una sección.
      if (
        this.section &&
        this.section.is_active &&
        this.user &&
        (this.section.professors.includes(this.user.id) ||
          this.user.is_superuser ||
          this.user.groups.includes(1))
      ) {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
        if (has_permission) return has_permission;
        return false;
      } else {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        return false;
      }
    },
    studentList() {
      if (!this.current_section) return [];
      if (this.input_search == "") return this.current_section.students;
      return this.current_section.students.filter((x) =>
        this.$filtered(
          String(x.first_name + " " + x.last_name),
          String(this.input_search)
        )
      );
    },
    gradeRangesList() {
      if (this.selected_range_group != null)
        return this.grade_ranges.filter(
          (x) => x.grade_range_group == this.selected_range_group
        );
      else return this.grade_ranges;
    },
  },
  methods: {
    getGradeRange() {
      this.$restful.Get("/evaluations2/grade-range-group/").then((response) => {
        this.grade_ranges_groups = response;
      });
      this.$restful.Get("/evaluations2/grade-range/").then((response) => {
        this.grade_ranges = response;
      });
    },
    gradeRangeBackground(grade) {
      for (let range of this.gradeRangesList) {
        if (range.include_start_grade && range.include_finish_grade) {
          if (grade >= range.start_grade && grade <= range.finish_grade) {
            return range.color;
          }
        } else if (range.include_start_grade && !range.include_finish_grade) {
          if (grade >= range.start_grade && grade < range.finish_grade) {
            return range.color;
          }
        } else if (!range.include_start_grade && range.include_finish_grade) {
          if (grade > range.start_grade && grade <= range.finish_grade) {
            return range.color;
          }
        } else {
          if (grade > range.start_grade && grade < range.finish_grade) {
            return range.color;
          }
        }
      }
      return null; // Retorna null si no encuentra ningún rango que coincida.
    },
    dropdownShouldOpen(VueSelect) {
      if (this.evaluationTypesList.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    publishGradeToAllEvaluatees(instrument_id) {
      this.$restful
        .Get(
          `/evaluations2/set_evaluatee_tests_to_is_published/?test=${instrument_id}`
        )
        .then(() => {
          toast(
            `${this.$getVisibleNames(
              "evaluations2.test",
              false,
              "Instrumento"
            )} publicado.`
          );
        });
    },
    getStudentIdsInTest(tests) {
      let student_ids = [];
      tests.forEach((x) => {
        student_ids = student_ids.concat(x.evaluatees);
      });
      return [...new Set(student_ids)];
    },
    showColumn(instruments) {
      let list = [];
      const instrument_ids = this.displayList
        .filter((x) => this.check_instrument.includes(x.index))
        .map((object) => object.id);
      list = instruments.filter((x) => instrument_ids.includes(x.id));
      return list;
    },
    getSectionsTeacher(reset_check_instrument = true) {
      if (this.user && !this.user.groups.includes(7)) {
        this.$store
          .dispatch(names.FETCH_SECTION, this.section_id)
          .then((section) => {
            if (section.egress_profile_matter != null) {
              this.$store
                .dispatch(
                  names.FETCH_EGRESS_PROFILE_MATTER,
                  section.egress_profile_matter
                )
                .then((egress_profile_matter) => {
                  this.$store
                    .dispatch(
                      names.FETCH_EGRESS_PROFILE,
                      egress_profile_matter.egress_profile
                    )
                    .then((egress_profile) => {
                      this.$store
                        .dispatch(names.FETCH_CAREER, egress_profile.career)
                        .then((career) => {
                          if (career.chosen_grade_range_group != null) {
                            this.career_range_group =
                              career.chosen_grade_range_group;
                            this.selected_range_group =
                              career.chosen_grade_range_group;
                          }
                        });
                    });
                  this.$store.dispatch(
                    names.FETCH_MATTER,
                    egress_profile_matter.matter
                  );
                });
            } else if (section.matter != null) {
              this.$store.dispatch(names.FETCH_MATTER, section.matter);
            }
          });
        this.displayList = [];
        if (reset_check_instrument) this.check_instrument = [];
        this.$restful
          .Get(`/evaluations2/section_grades/?section=${this.section_id}`)
          .then((response) => {
            this.current_section = response;
            const evaluation_types_ids = [
              ...new Set(response.evaluations.map((x) => x.type)),
            ];
            this.selected_types = evaluation_types_ids;
            this.evaluationTypesList = this.evaluationTypes.filter((x) =>
              evaluation_types_ids.includes(x.id)
            );
            let index = 0;
            response.evaluations.forEach((evaluation) => {
              evaluation.tests.forEach((instrument) => {
                this.displayList.push({
                  index: index,
                  id: instrument.id,
                  title: instrument.title,
                  evaluation_id: evaluation.id,
                });
                if (
                  evaluation.weighing > 0 &&
                  instrument.weighing > 0 &&
                  reset_check_instrument
                )
                  this.check_instrument.push(index);
                index += 1;
              });
            });
          });
      }
    },
    redirectEvaluatorView(evaluation_id) {
      this.$router.push({
        name: "EvaluatorView",
        params: {
          evaluation_id: evaluation_id,
        },
      });
    },
    getFinalGrade(section_grade) {
      let final_grade =
        section_grade.final_grade != null
          ? section_grade.final_grade
          : section_grade.manual_grade != null
          ? section_grade.manual_grade
          : section_grade.automated_grade != null
          ? section_grade.automated_grade
          : this.institution.min_grade;
      return parseFloat(final_grade.toFixed(1));
    },
    rowColspan() {
      let count = 2;
      if (this.current_section) {
        this.filterEvaluation.forEach((x) => {
          count += x.tests.length + 1;
        });
        return count;
      }
      return count;
    },
    // getStudentScore(student, label, evaluation, instrument) {
    //   if (label == "test") {
    //     let index = student.evaluatee_evaluations.findIndex(
    //       (x) => x.evaluation == evaluation.id
    //     );
    //     if (index != -1) {
    //       let student_score = student.evaluatee_evaluations[
    //         index
    //       ].evaluatee_tests.find((x) => x.test == instrument.id);
    //       return student_score;
    //     }
    //   }
    //   if (label == "evaluation") {
    //     let student_score = student.evaluatee_evaluations.find(
    //       (x) => x.evaluation == evaluation.id
    //     );
    //     return student_score;
    //   }
    //   return {
    //     automated_grade: null,
    //     publish: true,
    //     automated_score: 0,
    //     manual_score: 0,
    //     final_score: 0,
    //     start_time: null,
    //     manual_score_id: null,
    //   };
    // },
    resetTable() {
      this.getSectionsTeacher(false);
      toast("Nota actualizada.");
    },
  },
  mounted() {},
  watch: {},
  created() {
    this.getGradeRange();
    this.$store.dispatch(names.FETCH_NEW_EVALUATION_TYPES).then(() => {
      this.getSectionsTeacher();
    });
  },
};
</script>

<style scoped>
.min-width-input {
  min-width: 72px;
}
.vertical-align-td {
  vertical-align: middle;
}
.v-select-class >>> .vs__selected {
  background-color: #6c757d98;
  color: #fff !important;
}
.style-icon-btn {
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  cursor: pointer;
}
.style-icon-btn:hover {
  background-color: #ececec88; /* Color de fondo al pasar el mouse */
  border-radius: 50%;
  transform: scale(1.1); /* Aumenta ligeramente el tamaño al pasar el mouse */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.margin-icon-symbology {
  margin-top: -2px;
}
.flex-container {
  display: flex;
  margin-bottom: 1em;
}
.div-href {
  color: white;
}
.div-href:hover {
  color: #4dafff !important;
  text-decoration: underline;
  cursor: pointer;
}
.col-display-1,
.col-display-2 {
  min-width: 154px;
  margin-left: 2em;
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
}
.col-display-1:hover,
.col-display-2:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
  transition: all 0.3s;
}
.badge-score-evaluation {
  background: white;
  color: black;
  padding-top: 5px;
  font-weight: bold;
  font-size: 8pt;
  letter-spacing: 0.3px;
  margin-left: 15px;
}
.table-current-section {
  font-size: var(--secondary-font-size);
}
.header-section {
  background-color: var(--kl-menu-color) !important;
  color: white;
}
.report-ranges-caption {
  width: 100%;
  border: 1px solid #dee2e6;
  font-size: var(--secondary-font-size);
}
.report-ranges-header {
  text-align: left;
  padding: 0.5em 1em;
  padding-left: 2em;
  background: var(--kl-menu-color) !important;
  color: white;
  font-weight: bold;
}
.report-range {
  display: flex;
  margin-left: 0;
  padding: 0.5em 4em;
  border-bottom: 1px solid #dee2e6;
}
.range-label {
  font-weight: bold;
  text-align: left;
  width: 25%;
}
.report-range .range-value-red {
  background: #f19292;
  width: 25%;
}
.report-range .range-value-yellow {
  background: #fff1ac;
  width: 25%;
}
.report-range .range-value-green {
  background: #bbeaa6;
  width: 25%;
}
.info {
  background-color: var(--kl-menu-color) !important;
  border-radius: 10px;
  margin-top: 1.8%;
  padding: 0 2% 2% 2%;
}
.info-container {
  background-color: var(--secondary-font-color);
  padding: 1rem;
  border-radius: 10px;
}
.info-title {
  text-align: left;
  padding-top: 1%;
  margin-bottom: 2%;
  color: white !important;
}
.underline-style {
  text-decoration: underline;
}
.info-title .b-icon {
  margin-right: 1%;
}
.info .b-icon {
  max-width: 2rem;
  margin-left: 1%;
}
.info p {
  text-align: left;
  margin-top: -0.4%;
  margin-bottom: 0;
}
</style>